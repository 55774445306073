// extracted by mini-css-extract-plugin
export var body_background = "#f8f9fa";
export var container_areas_cards = "AreasCards-module__container_areas_cards___wxQrA";
export var container_areas_cards__btn_tell_us = "AreasCards-module__container_areas_cards__btn_tell_us___Rrz3M";
export var container_areas_cards__card = "AreasCards-module__container_areas_cards__card___81-hn";
export var container_areas_cards__card_content = "AreasCards-module__container_areas_cards__card_content___fE+Yu";
export var container_areas_cards__card_text = "AreasCards-module__container_areas_cards__card_text___XN4jm";
export var container_areas_cards__card_title = "AreasCards-module__container_areas_cards__card_title___K98+c";
export var container_areas_cards__container_cards = "AreasCards-module__container_areas_cards__container_cards___XBASs";
export var container_areas_cards__content_highlight = "AreasCards-module__container_areas_cards__content_highlight___mxWoO";
export var container_areas_cards__image = "AreasCards-module__container_areas_cards__image___tqJP6";
export var container_areas_cards__mobile = "AreasCards-module__container_areas_cards__mobile___mOWWs";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var lg = "1200px";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "#2ed3ae";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var white = "#fff";
export var xl = "1536px";
export var xxl = "2500px";
import React from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from '@components';
import * as styles from './AreasCards.module.scss';

const AreasCards = ({ intl: { formatMessage } }) => {
  return (
    <section className={styles.container_areas_cards}>
      <div className={styles.container_areas_cards__content_highlight}>
        <StaticImage src="../../../../../../images/highlight_1.webp" alt="highlight_1" placeholder="blurred"
          loading="lazy"
          layout="constrained"
          formats={["auto", "webp", "avif"]} />
      </div>
      <div className={styles.container_areas_cards__container_cards}>
        <div className={styles.container_areas_cards__card}>
          <StaticImage
            src="../../../../../../images/professional_job_1.webp"
            className={styles.container_areas_cards__image}
            alt="Areas"
            placeholder="blurred"
            loading="lazy"
            layout="constrained"
            formats={["auto", "webp", "avif"]}
          />
        </div>
        <div className={styles.container_areas_cards__card}>
          <div className={styles.container_areas_cards__card_content}>
            <h5 className={styles.container_areas_cards__card_title}>
              {formatMessage({ id: 'businessArea' })}
            </h5>
            <p className={styles.container_areas_cards__card_text}>
              {formatMessage({ id: 'weAreYourAlly' })}
            </p>
            <Button style={styles.container_areas_cards__btn_tell_us}>
              <Link to="/business">
                <p className="btn_text">{formatMessage({ id: 'tellUs' })}</p>
              </Link>
            </Button>
          </div>
        </div>
        <div className={styles.container_areas_cards__card}>
          <div className={styles.container_areas_cards__card_content}>
            <h5 className={styles.container_areas_cards__card_title}>
              {formatMessage({ id: 'professionalsArea' })}
            </h5>
            <p className={styles.container_areas_cards__card_text}>
              {formatMessage({ id: 'weBoostYour' })}
            </p>
            <Button style={styles.container_areas_cards__btn_tell_us}>
              <Link to="/professionals">
                <p className="btn_text">
                  {formatMessage({ id: 'joinUsProfessionalArea' })}
                </p>
              </Link>
            </Button>
          </div>
        </div>
        <div className={styles.container_areas_cards__card}>
          <StaticImage
            src="../../../../../../images/professional_job_2.webp"
            className={styles.container_areas_cards__image}
            alt="Areas"
            placeholder="blurred"
            loading="lazy"
            layout="constrained"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>
    </section>
  );
};

export default injectIntl(AreasCards);

AreasCards.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};
